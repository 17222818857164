<template>
  <section class="faq">
    <v-container class="mb-16">
      <p class="text-h5 text-lg-h4 font-weight-bold text-center mt-16 mb-8">Πολιτική Απορρήτου</p>

      <v-divider class="mx-auto w-25 mb-16"></v-divider>

      <p class="text-justify">
        <strong>Στην Maridela σεβόμαστε απόλυτα τα προσωπικά σας δεδομένα!</strong>
        <br><br>
        Το όνομα και η ηλεκτρονική διεύθυνση που δηλώνετε κατά την εγγραφή σας, αποθηκεύονται με ασφάλεια στην
        εταιρεία μας. Τα στοιχεία σας θα χρησιμοποιηθούν μόνο για την επικοινωνία μας μαζί σας.
        <br><br>
        Τα προσωπικά σας δεδομένα δεν θα χρησιμοποιηθούν για κανένα άλλο σκοπό εκτός των προαναφερθέντων!
        <br><br>
        <strong>Τα στοιχεία της κάρτας σας σε περίπτωση αγοράς συνδρομής με αυτήν δεν αποθηκεύονται και δεν επεξεργάζονται
          από το σύστημα μας.</strong>
        <br><br>
        Επιπλέον, συλλέγουμε ανώνυμα δεδομένα για την ανάλυση επισκεψιμότητας της ιστοσελίδας, όπως: τον τύπο και
        την έκδοση του φυλλομετρητή σας, την πλατφόρμα, το λειτουργικό σύστημα και την ημερομηνία προσπέλασης του ιστοχώρου.
        <br><br>
        Σε περίπτωση που επιθυμείτε να λάβετε γνώση των στοιχείων που έχουμε συλλέξει για εσάς ή την διαγραφή αυτών
        παρακαλώ επικοινωνήστε μαζί μας στο dpo@maridela.gr
      </p>
    </v-container>
  </section>
</template>

<script>
export default {
  created () {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
};
</script>
